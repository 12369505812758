/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* global Sentry */

// You can delete this file if you're not using it
import React from "react"
import { ThemeProvider } from "styled-components"
import { ParallaxProvider } from "react-scroll-parallax"
import { ErrorBoundary } from "react-error-boundary"

import theme from "./src/theme"

export const wrapRootElement = ({ element }) => (
  <ErrorBoundary
    onError={(error, componentStack) => {
      let eventId
      Sentry.withScope((scope) => {
        scope.setExtras(componentStack)
        eventId = Sentry.captureException(error)
      })
      Sentry.showReportDialog({ eventId })
    }}
  >
    <ThemeProvider theme={theme}>
      <ParallaxProvider>{element}</ParallaxProvider>
    </ThemeProvider>
  </ErrorBoundary>
)
